div.claimant-input {
  max-width: 550px;
  margin-bottom: 24px;
  display: block;
}
#prevToStarted {
    float: left;
}
input {
    opacity: '1';
}
.svg_button {
    forced-color-adjust: 'auto';
}
