#logo-table,
div.logo-table {
  width: 100%;
  margin: 0px;
  padding: 0px;
  background: url('./images/top-shade.png') bottom repeat-x;
  height: 188px;
}
.logoWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 1230px;
  margin: 0 auto;
  position: relative;
  padding-right: 30px !important;
  padding-left: 30px !important;
}
div.logo-div {
  font-size: 37px;
  color: #333333;
  order: -1;
  height: 80px;
  margin: 32px 0 0 0;
  line-height: 65px;
  flex: 1;
  white-space: nowrap;
  font-weight: 400;
}
a.logoLink {
  width: 180px;
  height: 62.81px;
  display: inline-block;
  text-indent: -1000px;
  background-image: url('./images/WSIB_logo_RGB_4.svg');
  background-repeat: no-repeat;
  margin-right: 32px;
}
.wsib-logo-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 50px 0 0;
  border-color: #00436d transparent transparent transparent;
  line-height: 0px;
  _border-color: #00436d #000000 #000000 #000000;
  _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
}
.toggle {
  display: flex;
  flex-direction: row;
  margin: 30px 30px 0 0;
  order: 1;
  flex: 0;
  z-index: 1001;
}
.bumpRS {
  margin-right: 40px;
}
.timer {
  color: #149717;
}
.headernav {
  font-family: 'Muli', sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #333333;
  text-decoration: none;
  background-color: transparent;
  border-width: 0px;
}
.headernav:hover,
.headernav:active,
a.headernav:focus {
  color: #0D73B8;
  text-decoration: none;
}

.link {
  color: #0D73B8;
  background: none!important;
  border: none;
  font-weight: bold;
  padding: 0!important;
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

.homediv {
  width: 90%;
  margin: auto;
  outline: none;
}

@media (max-width: 1200px) {
  #logo-table,
  div.logo-table {
    width: 100%;
  }
  .logoWrap {
    width: 94%;
  }
  .bumpRS {
    margin-right: 40px;
  }
}
@media (max-width: 1000px) {
  #logo-table,
  div.logo-table {
    width: 100%;
  }
  .logoWrap {
    width: 94%;
  }
  .bumpRS {
    margin-right: 30px !important;
  }
}
@media (max-width: 700px) {
  .bumpRS {
    margin-right: 20px !important;
  }
}
p {
	margin-bottom: 0.5rem;
  color:#333333;
  font-family: 'Muli',sans-serif;
}

.navbtn {
  border: 2px solid #0D73B8;
  border-radius: 24px;
  display: inline-block;
  padding: 10px 30px;
  width: '9em';
  color: #0D73B8;
  background-color: white;
  margin-bottom: 12px;
  margin-right: 12px;
}

.navbtn:focus:active,
.navbtn:focus:hover,
.navbtn:focus-visible
{
  background-color: white;
  color: #0D73B8;
	box-shadow:0 0 0 2px #ffffff ,0 0 0px 5px #292929;
	outline: 2px dotted transparent;
	outline-offset: 2px;
  border: 2px solid #0D73B8;
}

.navbtn:hover{
  color: white;
  border: 2px solid transparent;
  background-color: #292929;
}


.social-media-link {
  list-style: outside none none;
  display: flex;
  padding: 0;
}

.social-media-link li{
  margin:30px auto;
}

.skipToMainConetent {
  left: -999px;
  position: absolute;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: -999;
  font-weight: 600;
}

.skipToMainConetent:focus,
.skipToMainConetent:active {
  color: #0D73B8;
  left: auto;
  top: auto;
  width: auto;
  height: auto;
  padding: 5px;
  border-radius: 15px;
  text-align: center;
  font-size: 14px;
  z-index: 999;
  overflow: visible;
}

input[type='text']::placeholder {
  color: #333333;
  opacity: 1;
}

input[type='text'] {
  background-color: #FFFFFF;
  color: #333333;
}

input[type='tel']::placeholder {
  color: #333333;
  opacity: 1;
}

input[type='tel'] {
  background-color: #FFFFFF;
  color: #333333;
}

@media (max-width: 535px) {
  .pagestep .MuiStep-root {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (max-width: 410px) {
  .homediv .pagestep {
    display: block;
  }
}

@media (max-width: 320px) {
  .toggle {
    width: 60%;
    margin: 10px 10px 0 0;
  }
}

.btn-wsib {
  padding: 10px 30px;
  text-align: center;
  white-space: nowrap;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  border: 2px solid transparent;
  border-radius: 24px;
  color: white;
  background-color: #0D73B8;
  border-color: #0D73B8;
}

.btn-wsib:hover {
  color: #0D73B8;
  background-color: #FFFFFF;
  border-color: #0D73B8 !important;
}

.btn-wsib:focus:active,
.btn-wsib:focus:hover,
.btn-wsib:focus-visible
 {
  border: 2px solid #0D73B8;
  box-shadow:0 0 0 2px #ffffff ,0 0 0px 5px #292929;
}

.MuiDialogTitle-root {
  text-align: center;
}

span.Mui-disabled circle {
  fill: #E5E5E5;
}

span.Mui-disabled text {
  font-family: 'Muli',sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 23px;
  fill: #333333;
}

.MuiTypography-caption {
  color: #333333;
}

.MuiTouchRipple-root {
  opacity: 0;
}

input[type='checkbox']:focus-visible+span span span {
  opacity: 0;
}

input[type='checkbox']:focus-visible+svg {
  border: 1px solid #333333;
  background-color: #FFFFFF;
}

.PrivateSwitchBase-input:focus-visible+span svg {
  border: 1px solid #333333;
}

/*new logo changes */

@media (max-width: 1024px) {
    a.logoLink {
        width:140px;
        height:48px;
    }
    div.logo-div {
      span {
          display: none;
      }
    }
    #logo-table,
    div.logo-table {
        height: 156px;
    }
}

@media (max-width: 768px) {
    a.logoLink {
        width:140px;
        height:48px;
    }
    div.logo-div {
      span {
          display: none;
      }
    }
    .toggle {
        margin: 50px 0px;
    }
}

@media (max-width: 400px)  {
    a.logoLink {
        width:120px;
        height:33px;
    }
    div.logo-div {
      margin-top: 10px !important;
      span {
          display: none;
      }
    }
    .logoWrap{
      height: 40px;
      padding: 0px !important;
    }
    .toggle {
        margin: 15px 0px !important;
    }
    .bumpRS {
        margin: 0px!important;
  }
    #logo-table,
    div.logo-table {
        height: 70px;
    }
}
@media (max-width: 350px) {
    a.logoLink {
        width:70px;
        height:31px;
    }
    #logo-table,
    div.logo-table {
        height: 62px;
    }
}
@media (max-width: 235px) {
  .bumpRS {
    font-size: 12px;
    margin: 0px!important;
  }
  .headernav {
    font-size: 12px;
  }
  .toggle {
    flex-direction: column;
    align-items: flex-start;
    margin: 5px 0px !important;
  }
}

.Mui-error{
  color: #D90000 !important;
}
.Mui-error .MuiOutlinedInput-notchedOutline{
  border-color:  #D90000 !important;
}
.error {
  color:  #D90000 !important;
}
.MuiLink-root{
  color: #0D73B8 !important;
}
*, *::before, *::after {
  box-sizing: border-box;
}