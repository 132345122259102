.fileItemContainer {
    background-color: #f8f9fa;
    max-width: fit-content !important;
    width: 100%;
    padding: 24px 10px 24px 0!important;
}
.verticalLine {
    border-left: 6px solid #003359;
    height: auto;
    background-color: #f8f9fa;
    padding-left: 30px;
    max-width: fit-content !important;
}
