body {
  height: 100%;
  margin: 0;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Muli',sans-serif;
  color: #333333;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #003359 !important;
  color: white !important;
}

@media (min-width: 1200px) {
  .toggle-new {
    width: 1140px;
    text-align: right;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    z-index: 1001;
    padding-right: 20px;
    padding-left: 20px;
  }
}

.toggle-new a:hover {
  color: mat-color($primary, default) !important;
  cursor: pointer;
}

.alert-danger {
  color: mat-color($warn, default);
  font-weight: bold;
  background-color: #ffffff;
  border-color: #ffffff;
}

.mat-success {
  background-color: mat-color($primary, default) !important;
  color: white !important;
}
/* Footer */

.bigfooter-links {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.25em;
  padding-right: 1.25em;
  margin-top: 40px;
  margin-left: 11%;
  padding: 40px 10px 0px 10px;
}

@media (min-width: 900px) {
  .bigfooter-links {
      max-width: 1140px;
      padding-left: 2.5em;
      padding-right: 2.5em;
      display: flex;
      flex-wrap: wrap;
  }
}

.menu--footer, .menu--footer-fr {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66%;
  flex: 0 0 66%;
  display: block;
}

.menu--footer .menu, .menu--footer-fr .menu {
  padding-left: 0;
  margin-top: 0;
  overflow: visible;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

ul.menu {
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

ul.menu > li {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
  display: flex;
  list-style-image: none;
  list-style-type: none;
  list-style: none;
  margin-bottom: 14px;
  text-align: -webkit-match-parent;
}

.menu--footer .menu li:nth-child(-n+2) a, .menu--footer-fr .menu li:nth-child(-n+2) a {
  border-top: 0;
}

.menu--footer .menu a, .menu--footer-fr .menu a {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  transition: color 0.3s ease-out;
  text-decoration: none;
  background-color: transparent;
  color: #4d4d4d;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 14px;
  text-decoration: none;
  font-weight: 600;
}

.menu--footer .menu a:-webkit-any-link, .menu--footer-fr .menu a:-webkit-any-link {
  cursor: pointer;
}

@media (min-width: 900px) {
  .menu--footer .menu a, .menu--footer-fr .menu a {
    padding: 0.625em 0.625em;
    border: 0;
  }
}

.menu a:hover,
.menu a:focus {
  text-decoration: underline;
}

.clearfix {
  display: block;
}

.clearfix:after {
  display: table;
  clear: both;
  content: "";
}

.clearfix p {
  font-size: 14px;
  line-height: 1.5em;
  font-family: "Muli",sans-serif;
  margin-top: 0;
  display: block;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.region--footer-first {
  margin-top: 2.44141em;
  border-top: 1px solid #e1e1e1;
}

@media (min-width: 900px) {
  .region--footer-first {
      padding-top: 0;
      padding-bottom: 0;
  }
}

.social-media-container {
  text-align: center;
  margin: 0 auto 0 auto;
}

ul.social-media-icons {
  overflow: hidden;
}

ul.social-media-icons li {
  display: inline-block;
}

ul.social-media-icons li a {
  font-size: 16px;
  font-weight: 600;
  color: #0076bf;
  text-decoration: none;
}

[class^='icon-social-media'],
[class*='icon-social-media'] {
  /*background-image: url("http://www.wsib.on.ca/WSIBPortal/img/icons-social-media.png");*/
  /* background-image: url("/assets/images/social-media-icons.png");*/
  background-image: url('https://loginsit.lfb.wsib.ca/assets/images/social-media-icons.png');
  background-repeat: no-repeat;
  display: inline-block;
  height: 62px;
  margin: 2px;
  vertical-align: text-top;
  width: 62px;
  text-indent: -1000px;
}

.icon-social-media-linkedin {
  background-position: -92px 0;
}

.icon-social-media-twitter {
  background-position: 0px 0px;
  margin-right: 30px;
}

.icon-social-media-youtube {
  background-position: -70px 0px;
  margin-right: 30px;
}
.icon-social-media-linkedin {
  background-position: -140px 0px;
  margin-right: 30px;
}
.icon-social-media-instagram {
  background-position: -210px 0px;
}

.icon-social-media-linkedin:hover {
  background-color: #1582bb;
}

.icon-social-media-twitter:hover {
  background-color: #00b4f2;
}

.icon-social-media-youtube:hover {
  background-color: #cc181e;
}

.copyright {
  font-size: 11px;
  color: #149717;
  padding: 10px 0px 90px 10px;
}

.agreeWrap {
  padding: 10px;
}
a {
  color: #0D73B8;
  font-weight: bold;
}
a:hover {
  cursor: pointer;
}
/* Footer mobile view - Chris */
@media (max-width: 1200px) {
  ul.footerUL li a {
    font-size: 13px;
  }
}
@media (max-width: 1000px) {
  ul.footerUL li a {
    font-size: 12px;
  }
}
@media (min-width: 1000px) {
  .topAlert {
    margin-right: 25px;
  }
}
.visually-hidden {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
  width: 1px;
  word-wrap: normal;
}
.midNight {
  font-size: 14px;
  color: #ffffff;
  width: 100%;
  background-color: #003359;
  padding: 1.5625em 0em;
  text-align: center;
  margin-top: 50px;
}
.th-wsib {
  border-bottom: 2px solid #149717 !important ;
}

@media (min-width: 1200px) {
  .logo-new {
    width: 1140px;
    margin-left: auto;
    margin-right: auto;
    padding-right: 15px;
    padding-left: 15px;
    margin-top: 22px;
  }
  .logo-new h1 {
    font-size: 40px;
    margin-top: 20px;
    color: #333333;
    font-weight: lighter !important;
  }
}
@media (min-width: 1200px) {
  .toggle-new {
    width: 1140px;
    text-align: right;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    z-index: 1001;
    padding-right: 20px;
    padding-left: 20px;
  }
}

.toggle-new a:hover {
  color: #0076bf !important;
  cursor: pointer;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .logo-new {
    width: 960px;
    margin-left: auto;
    margin-right: auto;
    padding-right: 15px;
    padding-left: 15px;
    margin-top: 22px;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .toggle-new {
    width: 960px;
    text-align: right;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    z-index: 1001;
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .logo-new {
    width: 720px;
    margin-left: auto;
    margin-right: auto;
    padding-right: 15px;
    padding-left: 15px;
    margin-top: 22px;
  }
  .logo-new h1 {
    font-size: 40px;
    margin-top: 20px;
    color: #333333;
    font-weight: lighter !important;
    margin-left: 20px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .logo-new h1 {
    font-size: 40px;
    margin-top: 20px;
    color: #333333;
    font-weight: lighter !important;
    margin-left: 20px;
  }
}
@media (max-width: 768px) {
  .logo-new h1 {
    font-size: 40px;
    margin-top: 20px;
    color: #333333;
    font-weight: lighter !important;
    margin-left: 50px;
  }
}
@media (max-width: 425px) {
  .logo-new h1 {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .toggle-new {
    width: 720px;
    text-align: right;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    z-index: 1001;
    padding-right: 20px;
    padding-left: 20px;
  }
}
.mainHeadnew {
  font-size: 40px;
  margin-top: 30px;
  font-weight: lighter !important;
  margin-top: 40px;
  margin-bottom: 30px;
}

ol {
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}

.margin-l-md {
  margin-left: 15px;
}
.padding-l-md {
  padding-left: 15px;
}
.footerTableLS {
  width: 410px;
}
.footerTableCS {
  width: 310px;
}

/*
// display print content in confirmation page
@media screen {
	#section-to-print {
      margin-left: 0px;
  }
}
*/

/* hide print content in confirmation page*/
@media screen {
  #section-to-print,
  #section-to-print * {
    visibility: hidden;
    height: 0px;
  }
  #section-to-print {
    position: absolute;
    left: 0;
    top: 0;
  }
}

/* only print the sections of the application that are marked #section-to-print*/
@media print {
  body * {
    visibility: hidden;
    height: 0px;
  }
  #section-to-print,
  #section-to-print * {
    visibility: visible;
    height: auto;
  }
  #section-to-print {
    margin-left: 8px;
    position: absolute;
    left: 0;
    top: 40px;
  }
}
