.warningContainer{
    background: #FCF8E3;
    padding: 16px;
    margin-top: 8px;
    display: inline-block;
}

.imageFigure {
    margin: 0px !important;
    padding-left: 5px;
}

.warningIcon {
    float: left;
    vertical-align: top;
    .infoIcon {
        max-width: 24px;
        height: auto;
        vertical-align: middle
    }
}

.warningText {
    float: right;
    vertical-align: top;
    padding-left: 16px;
}
