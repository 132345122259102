  .combobox-list {
    position: relative;
    min-width: 46px;
    width: -webkit-fill-available;
    margin-right: 8px;
  }
  
  .combobox .group {
    display: inline-flex;
    cursor: auto;
    border: none;
  }
  
  .combobox input,
  .combobox button {
    min-height: 38px;
    background-color: #FFFFFF;
    color: black;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    vertical-align: bottom;
    border: 1px solid gray;
    position: relative;
    cursor: auto;
  }
  
  .combobox input {
    width: 90%;
    border-right: none;
    outline: none;
    min-width: 46px;
  }

  @media (max-width: 470px) {
    .combobox input {
        width: 70%;
        border-right: none;
        outline: none;
      }
  }
  
  .combobox button {
    width: 19px;
    border-left: none;
    outline: none;
    color: hsl(0, 0%, 80%);
  }
  
  /*.combobox button[aria-expanded="true"] svg {
    transform: rotate(180deg) translate(0, -3px);
  }*/
  
  ul.calendar-list[role="listbox"] {
    margin: 0;
    padding: 0;
    position: absolute;
    left: 4px;
    top: 40px;
    list-style: none;
    background-color: #FFFFFF;
    display: none;
    box-sizing: border-box;
    border: 2px currentcolor solid;
    max-height: 250px;
    width: 168px;
    overflow-x: hidden;
    font-size: 87.5%;
    cursor: default;
    z-index: 100;
  }
  
  ul.calendar-list[role="listbox"] li[role="option"] {
    margin: 0;
    display: block;
    padding-left: 3px;
    padding-top: 2px;
    padding-bottom: 2px;
  }
  
  /* focus and hover styling */
  
  .combobox .group.focus,
  .combobox .group:hover {
    border: 1px solid #5E6A71;
    border-radius: 3px;
  }
  
  .combobox .group:focus polygon,
  .combobox .group:hover polygon {
    fill-opacity: 1;
  }
  
  .combobox .group.focus input,
  .combobox .group.focus button,
  .combobox .group input:hover,
  .combobox .group button:hover {
    background-color: #FFFFFF;
  }
  
  [role="listbox"].focus [role="option"][aria-selected="true"],
  [role="listbox"] [role="option"]:hover {
    background-color: #EFF0F1;
    padding-top: 0;
    padding-bottom: 0;
    border-top: 2px solid currentcolor;
    border-bottom: 2px solid currentcolor;
  }
  
