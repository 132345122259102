div.doclist {
  display: list-item; /* This has to be "list-item"                                               */
  list-style-type: disc; /* See https://developer.mozilla.org/en-US/docs/Web/CSS/list-style-type     */
  list-style-position: inside; /* See https://developer.mozilla.org/en-US/docs/Web/CSS/list-style-position */
}
.edobUploadLinks li {
  color: #0D73B8;
}

.doclable {
  display: flex;
  flex-direction: row;
}

@media (min-width: 581px) {
  .doclable {
    float: left;
  }
}

.warningContainer{
    background: #FCF8E3;
    padding: 16px;
    margin-top: 8px;
    display: inline-block;
}

.imageFigure {
    margin: 0px !important;
    padding-left: 5px;
}

.warningIcon {
    float: left;
    vertical-align: top;
    .infoIcon {
        max-width: 24px;
        height: auto;
        vertical-align: middle
    }
}

.warningText {
    float: right;
    vertical-align: top;
    padding-left: 16px;
}
