#session_leave_button {
    background: none;
    border: none;
    color: #0076BF;
    text-decoration: underline;
    cursor: pointer;
    padding: 10px 30px;
    font-size: '16px';
    text-align: 'center';
    margin: '24px 0px 40px 0px';
    font-family: 'Muli',sans-serif;
    font-weight: normal;
}
#session_leave_button:focus:hover, 
#session_leave_button:focus:active{
    border-radius: 24px;
    display: inline-block;
    background-color: white;
    color: #0076BF;
    outline: 2px dotted transparent;
    outline-offset: 2px;
    border: 2px solid  #003359;
    text-decoration: none;
}
#session_leave_button:hover {
    border-radius: 24px;
    display: inline-block;
    background-color: #003359;
    color: white;
    border: 2px solid transparent;
    text-decoration: none;
}
#extend_button {
    white-space: break-spaces;
}
